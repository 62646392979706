import { ActionIcon, Box, Button, Card, Flex, Grid, NumberInput, PasswordInput, Select, Space, Text, TextInput, Title } from "@mantine/core"
import { IconArrowDown, IconArrowUp, IconDeviceFloppy, IconExclamationMark, IconPencil, IconPlus, IconTrash } from "@tabler/icons-react"
import { useEffect, useMemo, useState } from 'react';
import { MantineReactTable } from 'mantine-react-table';
var _ = require('lodash');
const fd = require('../../utilities/findDifferences.js')
const mc = require("../../utilities/makeChanges.js")


const Picklist = (props) => {
    const [rerenderKey, setRerenderKey] = useState(0);

    const [editingBucketName, setEditingBucketName] = useState("");
    const [editingNameValue, setEditingNameValue] = useState("");

    const [localUpdated, setLocalUpdated] = useState(false);

    const [dataBucketFieldOptions, setDataBucketFieldOptions] = useState([]);

    const [passwordAttempt, setPasswordAttempt] = useState('')
    const [passwordValid, setPasswordValid] = useState(false)
    const [attemptFailed, setAttemptFailed] = useState(false)



    const freeFormColumns = useMemo(
        () => [

            {
                accessorKey: 'team',
                header: 'Team',
            },
            {
                accessorKey: 'notes',
                header: 'Notes',
            },



        ],
        [],
    );

    const dataColumns = useMemo(
        () => [

            {
                accessorKey: 'team',
                header: 'Team',
            },
            {
                accessorKey: 'data',
                header: 'Data'
            },
            {
                accessorKey: 'notes',
                header: 'Notes',
            },



        ],
        [],
    );


    const [buckets, setBuckets] = useState({});

    const [oldBuckets, setOldBuckets] = useState({});

    const [selectingBucketType, setSelectingBucketType] = useState(false);

    useEffect(() => {
        const fetchFunc = async () => {
            await fetch("https://server.palyrobotics.com/event/get-data-bucket-fields/" + props.event).then((res) => res.json()).then((data) => {
                console.log(data)
                setDataBucketFieldOptions(data);
            })
        }
        fetchFunc();
    }, [])

    useEffect(() => {
        let stopRequesting = false;
        let lastUpdate = 0.0;
        const debounceTime = 1000;

        const fetchData = async () => {
            setTimeout(async function () {
                if (!stopRequesting) {
                    try {
                        const res = await fetch("https://server.palyrobotics.com/event/get-picklist/" + props.event);
                        // console.log(res);
                        const data = await res.json();
                        // console.log(props.event);
                        // console.log(data);

                        if (!_.isEqual(buckets, data) && !localUpdated) {
                            console.log("DIFF FOUND");
                            const newBuckets = mc.makeChanges(fd.findDifferences(buckets, data, [], localUpdated), buckets);
                            setBuckets(newBuckets);
                            setOldBuckets(newBuckets);
                            lastUpdate = Date.now();
                            setRerenderKey(prevKey => prevKey + 1);
                        }

                        else if (localUpdated && !_.isEqual(buckets, data)) {
                            console.log("Local Change");
                            console.log("Buckets: ", buckets);
                            console.log(_.isEqual(buckets, oldBuckets));

                            const now = Date.now();
                            if (now - lastUpdate > debounceTime) {
                                lastUpdate = Date.now();

                                const diffs = fd.findDifferences(oldBuckets, buckets, [], false);
                                console.log(diffs);
                                if (diffs != "no change") {
                                    await fetch("https://server.palyrobotics.com/event/set-picklist/" + props.event, {
                                        method: "POST",
                                        headers: {
                                            "Content-Type": "application/json"
                                        },
                                        body: JSON.stringify(diffs)
                                    }).then(res => res.text())
                                        .then(data => {
                                            console.log(data);
                                        });
                                    setOldBuckets(buckets);
                                    setLocalUpdated(false);
                                }
                            }
                        }
                    } catch (error) {
                        console.error("Error fetching picklist:", error);
                    }

                    fetchData();
                }
            }, 500);
        };
        fetchData();

        return function cleanup() {
            stopRequesting = true;
        };
    }, [buckets, localUpdated]);


    const handleSaveCell = (cell, value, name) => {
        const tempTableData = buckets[name].rows.map((row, index) => {
            if (index === cell.row.index) {
                setLocalUpdated(true);
                return { ...row, [cell.column.id]: value };
            }
            return row;
        });

        var tempBuckets = JSON.parse(JSON.stringify(buckets))
        tempBuckets[name].rows = tempTableData
        console.log(tempBuckets);
        setLocalUpdated(true);
        setBuckets(tempBuckets);
    };

    const renameKeys = (keysMap, object) => {

        return (Object.keys(object).reduce(
            (acc, key) => ({
                ...acc,
                ...{ [keysMap[key] || key]: object[key] },
            }),
            {}
        ))

    }

    const yoinkDataList = async (bucketName, field, numTeams, order) => {
        console.log("yoink")
        console.log(props.event, field, numTeams, order)
        await fetch(`https://server.palyrobotics.com/event/get-team-data-list/${props.event}/${field}/${numTeams}/${order}`).then(res =>
            res.text()
        ).then(d => {
            console.log(d);
            const data = JSON.parse(d);
            let newBucketRows = [];
            for (let i = 0; i < data.length; i++) {
                newBucketRows.push({ "team": data[i][0], "data": data[i][1], "notes": "" })
            }
            let temp = JSON.parse(JSON.stringify(buckets))
            temp[bucketName].field = field;
            temp[bucketName].rows = newBucketRows;
            temp[bucketName].numRows = numTeams;
            temp[bucketName].order = order;
            console.log(temp[bucketName])
            setLocalUpdated(true)
            setBuckets(temp);
            setRerenderKey(prevKey => prevKey + 1);

        })
    }

    const handlePasswordCheck = async () => {
        console.log("checking")
        await fetch('https://server.palyrobotics.com/attendance/check-password/' + passwordAttempt).then(
            res => res.text()
        ).then(
            data => {
                console.log(data)
                console.log(typeof (data))
                setPasswordValid(data === "true")
                setAttemptFailed(data === "false")
            }
        )

    }




    return passwordValid ? (

        <>
            <Flex direction={"column"} style={{ border: "0px solid red", }} h={"100%"}>
                <Flex style={{ padding: "1%", border: "0px solid red" }} >
                    <ActionIcon onClick={() => {
                        let newBucketOk = true;
                        Object.keys(buckets).map((bucket) => {
                            console.log(bucket == "Unnamed Bucket")
                            if (bucket == "Unnamed Bucket") {
                                newBucketOk = false;
                            }
                        })
                        if (newBucketOk) {
                            console.log("YAY NEW BUCKER")
                            setSelectingBucketType(true);
                            // setLocalUpdated(true);
                            // setBuckets({ ...buckets, "Unnamed Bucket": { "rows": [{ team: '', notes: '' }], "type": "free" } });
                        }

                    }} size={"100%"} color="green.5"><IconPlus size={48} /></ActionIcon>
                </Flex>

                {selectingBucketType &&
                    <Flex style={{ justifyContent: "center", border: "0px solid red" }}>
                        <Button style={{ marginLeft: "auto", marginRight: "auto", marginTop: "3%", marginBottom: "3%", border: "0px solid red", backgroundColor: "green" }} onClick={() => {
                            setLocalUpdated(true);
                            setBuckets({ ...buckets, "Unnamed Bucket": { "rows": [{ team: '', notes: '' }], "type": "free" } });
                            setSelectingBucketType(false);
                        }}>Freeform Bucket</Button>
                        <Button style={{ marginRight: "2.5%", marginTop: "3%", marginBottom: "3%", border: "0px solid red", backgroundColor: "red" }} onClick={() => { setSelectingBucketType(false) }}>Cancel</Button>
                        <Button style={{ marginLeft: "auto", marginRight: "auto", marginTop: "3%", marginBottom: "3%", border: "0px solid red", backgroundColor: "green" }} onClick={() => {
                            setLocalUpdated(true);
                            setBuckets({ ...buckets, "Unnamed Bucket": { "rows": [{ team: '', data: '', notes: '' }], "type": "data", "field": "none", "order": "desc", "numRows": 1 } });
                            setSelectingBucketType(false);
                        }}>Data Bucket</Button>

                    </Flex>}

                {Object.keys(buckets).map((name) => {
                    // const updatedBuckets = buckets[name].map((item, index) => ({
                    //     ...item,
                    //     id: item.id || `${name}-${index}` 
                    // }));  
                    // setBuckets(updatedBuckets);                  

                    return (
                        <Flex style={{ border: "0px solid red", justifyContent: "center" }} mb="lg">
                            <Card style={{ justifyContent: "center", }} w={"100%"}>
                                <Card.Section >
                                    <Card color="blue.7" style={{ backgroundColor: "#227319", }}>
                                        <Flex direction={"column"} align="center">


                                            {editingBucketName === name ?
                                                <TextInput value={editingNameValue} placeholder="Enter Name" onChange={(e) => {
                                                    setEditingNameValue(e.target.value)
                                                    // setLocalUpdated(true)
                                                }}
                                                    onKeyDown={(e) => {
                                                        console.log(e.key)
                                                        if (e.key === "Enter") {
                                                            var keysMap = {}
                                                            keysMap[name] = editingNameValue
                                                            setLocalUpdated(true);
                                                            setBuckets(renameKeys(keysMap, buckets))

                                                            setEditingBucketName("")
                                                        }
                                                    }}
                                                />
                                                :
                                                <Text align="center">{name}</Text>
                                            }


                                            {buckets[name].type == "data" ?
                                                //DATA BUCKET HEADER
                                                <Flex style={{ display: "flex", border: "0px solid red", width: "100%" }}>

                                                    <Flex style={{ border: "0px solid red", flex: 0.33, justifyContent: "center" }}>
                                                        <Select
                                                            placeholder="Pick Field"
                                                            data={dataBucketFieldOptions}
                                                            searchable
                                                            dropdownPosition="bottom"
                                                            withinPortal
                                                            zIndex={1000}
                                                            value={buckets[name].field}
                                                            onChange={(option) => {

                                                                yoinkDataList(name, option, buckets[name].numRows, buckets[name].order);
                                                                setRerenderKey(prevKey => prevKey + 1);


                                                            }}
                                                            style={{}}
                                                        />
                                                    </Flex>

                                                    <Flex style={{ border: "0px solid red", justifyContent: "center", alignItems: "center", flex: 0.33 }}>
                                                        <ActionIcon
                                                            ml={"md"}
                                                            color="red"
                                                            onClick={() => {
                                                                if (window.confirm("Are You Sure You Want To Delete This Bucket?")) {
                                                                    var tempBuckets = JSON.parse(JSON.stringify(buckets))
                                                                    delete tempBuckets[name]
                                                                    setLocalUpdated(true)
                                                                    setBuckets(tempBuckets)
                                                                }
                                                            }}
                                                            style={{ border: "0px solid red" }}
                                                        >
                                                            <IconTrash />
                                                        </ActionIcon>

                                                        <ActionIcon
                                                            ml={"md"}
                                                            onClick={() => {

                                                                if (editingBucketName === name) {
                                                                    var keysMap = {}
                                                                    keysMap[name] = editingNameValue
                                                                    setLocalUpdated(true)
                                                                    setBuckets(renameKeys(keysMap, buckets))
                                                                    setEditingBucketName("")
                                                                } else {
                                                                    setEditingBucketName(name)
                                                                    setEditingNameValue(name)
                                                                    setLocalUpdated(true)
                                                                }
                                                            }} color="green.5"
                                                            style={{ border: "0px solid red" }}
                                                        >
                                                            {editingBucketName === name ?
                                                                <IconDeviceFloppy size={48} />
                                                                :
                                                                <IconPencil size={48} />}
                                                        </ActionIcon>
                                                    </Flex>


                                                    <div style={{ display: "flex", border: "0px solid red", justifyContent: "center", alignItems: "center", flex: 0.33 }}>
                                                        <NumberInput
                                                            min={1}
                                                            style={{ display: "flex", flex: 0.5 }}
                                                            placeholder="Num of Rows"
                                                            value={buckets[name].numRows}
                                                            onChange={(e) => {

                                                                if (e >= 1) {
                                                                    yoinkDataList(name, buckets[name].field, e, buckets[name].order);
                                                                    setRerenderKey(prevKey => prevKey + 1);
                                                                }
                                                            }}

                                                        />
                                                        <Button style={{ display: "flex", flex: 0.2, justifyContent: "center", marginLeft: "5%", width: "auto", alignItems: "center", padding: "0px", backgroundColor: "transparent", border: "0px solid red" }} onClick={() => {
                                                            if (buckets[name].order == "desc") {
                                                                yoinkDataList(name, buckets[name].field, buckets[name].numRows, "asc");
                                                            } else {
                                                                yoinkDataList(name, buckets[name].field, buckets[name].numRows, "desc");
                                                            }
                                                            setRerenderKey(prevKey => prevKey + 1);
                                                        }}>

                                                            {buckets[name].order == "desc" ?

                                                                <IconArrowUp style={{ border: "0px solid red" }} />

                                                                :
                                                                <IconArrowDown style={{ border: "0px solid red" }} />


                                                            }
                                                        </Button>
                                                    </div>

                                                </Flex>
                                                :
                                                //FREE BUCKET HEADER
                                                <Flex>
                                                    <ActionIcon
                                                        mr={"md"}
                                                        color="red"
                                                        onClick={() => {
                                                            if (window.confirm("Are You Sure You Want To Delete This Bucket?")) {
                                                                var tempBuckets = JSON.parse(JSON.stringify(buckets))
                                                                delete tempBuckets[name]
                                                                setLocalUpdated(true)
                                                                setBuckets(tempBuckets)
                                                            }
                                                        }}
                                                    >
                                                        <IconTrash />
                                                    </ActionIcon>

                                                    <ActionIcon mr={"md"} onClick={() => {
                                                        if (editingBucketName === name) {
                                                            var keysMap = {}
                                                            keysMap[name] = editingNameValue
                                                            setLocalUpdated(true)
                                                            setBuckets(renameKeys(keysMap, buckets))
                                                            setEditingBucketName("")
                                                        } else {
                                                            setEditingBucketName(name)
                                                            setEditingNameValue(name)
                                                            setLocalUpdated(true)
                                                        }
                                                    }} color="green.5">
                                                        {editingBucketName === name ?
                                                            <IconDeviceFloppy size={48} />
                                                            :
                                                            <IconPencil size={48} />}
                                                    </ActionIcon>


                                                    <ActionIcon onClick={() => {
                                                        console.log(buckets)
                                                        var tempBuckets = JSON.parse(JSON.stringify(buckets))
                                                        tempBuckets[name].rows = [...buckets[name].rows, { "team": "", "notes": "" }]
                                                        setLocalUpdated(true)
                                                        setBuckets(tempBuckets)
                                                    }} color="green.5">
                                                        <IconPlus size={48} />
                                                    </ActionIcon>



                                                </Flex>
                                            }

                                        </Flex>
                                    </Card>

                                </Card.Section>

                                <Grid>
                                    <Grid.Col style={{ justifyContent: "center" }}>

                                        <MantineReactTable

                                            key={rerenderKey}

                                            autoResetPageIndex={false}
                                            columns={buckets[name].type == "free" ? freeFormColumns : dataColumns}
                                            data={buckets[name].rows}
                                            enableTopToolbar={false}
                                            enableBottomToolbar={false}
                                            enableRowOrdering
                                            enableSorting={false}
                                            mantineRowDragHandleProps={({ table }) => ({
                                                onDragEnd: () => {
                                                    const { draggingRow, hoveredRow } = table.getState();
                                                    if (hoveredRow && draggingRow) {
                                                        const result = Array.from(buckets[name].rows);
                                                        const [removed] = result.splice(draggingRow.index, 1);
                                                        result.splice(hoveredRow.index, 0, removed);
                                                        var tempBuckets = JSON.parse(JSON.stringify(buckets))
                                                        tempBuckets[name].rows = result
                                                        setLocalUpdated(true);
                                                        setBuckets(tempBuckets);
                                                        setRerenderKey(prevKey => prevKey + 1);
                                                    }
                                                },
                                            })}
                                            editDisplayMode="table"
                                            enableEditing
                                            enableRowActions={buckets[name].type == "free"}
                                            initialState={{ pagination: { pageSize: 1000 } }}
                                            mantineEditTextInputProps={({ cell }) => ({
                                                //onBlur is more efficient, but could use onChange instead
                                                onChange: (event) => {
                                                    handleSaveCell(cell, event.target.value, name);
                                                },
                                                variant: 'unstyled', //default for editDisplayMode="table"
                                            })}

                                            renderRowActions={({ row }) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                                                    <ActionIcon key={row.index} color="red" onClick={() => {
                                                        if (buckets[name].rows.length != 1) {
                                                            var temp = JSON.parse(JSON.stringify(buckets));
                                                            // const old = {...oldBuckets};
                                                            temp[name].rows.splice(row.index, 1);
                                                            console.log(buckets);
                                                            console.log(oldBuckets);

                                                            console.log("Deleting row:");
                                                            console.log(temp);

                                                            setLocalUpdated(true);
                                                            // setOldBuckets(old);
                                                            setBuckets(temp);

                                                            setRerenderKey(prevKey => prevKey + 1);
                                                        }
                                                    }}>
                                                        <IconTrash />
                                                    </ActionIcon>
                                                </Box>
                                            )}


                                        />

                                    </Grid.Col>
                                </Grid>

                            </Card>

                        </Flex>
                    )
                }
                )
                }

            </Flex>
        </>
    ) : (
        <>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <Title className="text-center mb-8 text-2xl " align="center">Password Required</Title>

                <PasswordInput withAsterisk onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        handlePasswordCheck()
                    }
                }} className="w-1/2 " style={{width: "25%"}} value={passwordAttempt} onChange={(e) => setPasswordAttempt(e.target.value)}></PasswordInput>
                <Button className="mt-5 w-1/2" onClick={() => handlePasswordCheck()}>Check</Button>
                {attemptFailed &&
                    <Card
                        className="h-12 mt-4"

                        icon={IconExclamationMark}
                        color="red"
                    >
                        Incorrect Password
                    </Card>
                }
            </div>
        </>
    )
}

export default Picklist