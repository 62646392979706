import React, { useState, useEffect } from "react";
import { Card, Grid, Title, Space, Text } from '@mantine/core';
import { useParams } from 'react-router-dom';

import PredictionCard from "../PredictionCard";
import PrematchCard from "../PrematchCard";

const Match = (props) => {
    const { number } = useParams();
    const [loading, setLoading] = useState();
    const [red, setRed] = useState(); // list of red alliance teams
    const [blue, setBlue] = useState(); // list of blue alliance teams
    const [redPoints, setRedPoints] = useState();
    const [bluePoints, setBluePoints] = useState();
    const [redStats, setRedStats] = useState();
    const [blueStats, setBlueStats] = useState();
    const [red1, setRed1] = useState();
    const [red2, setRed2] = useState();
    const [red3, setRed3] = useState();
    const [blue1, setBlue1] = useState();
    const [blue2, setBlue2] = useState();
    const [blue3, setBlue3] = useState();

    const [matchHasHappened, setMatchHasHappened] = useState(false);

    const getStatboticsStuffCusFunnyWhyNotNoMatchDataYetSoNeedPredictionBecauseFunnyWhyNotXanderWantDataBigDataFunnyRobotsGoCrazyDataFromRobotsGetAllTimeDataButForSomeReasonThisIsntWorkingRightNowAndICantFetchTheDataButIWillTryToFixItUpdateItStillIsBrokenIMostlyFixedItButThisFunctionIsGettingCalledInAWeirdLoopThatIsBreakingTheWebsite = async () => {
        await fetch(`https://api.statbotics.io/v3/team_year/${red[0].replace("frc", "")}/2025`)
            .then((response) => response.json())
            .then((data) => {
                setRed1(data);
            });
        await fetch(`https://api.statbotics.io/v3/team_year/${red[1].replace("frc", "")}/2025`)
            .then((response) => response.json())
            .then((data) => {
                setRed2(data);
            });
        await fetch(`https://api.statbotics.io/v3/team_year/${red[2].replace("frc", "")}/2025`)
            .then((response) => response.json())
            .then((data) => {
                setRed3(data);
            });
        await fetch(`https://api.statbotics.io/v3/team_year/${blue[0].replace("frc", "")}/2025`)
            .then((response) => response.json())
            .then((data) => {
                setBlue1(data);
            });
        await fetch(`https://api.statbotics.io/v3/team_year/${blue[1].replace("frc", "")}/2025`)
            .then((response) => response.json())
            .then((data) => {
                setBlue2(data);
            });
        await fetch(`https://api.statbotics.io/v3/team_year/${blue[2].replace("frc", "")}/2025`)
            .then((response) => response.json())
            .then((data) => {
                setBlue3(data);
            });
    }

    useEffect(() => {
        setLoading(true);
    }, [props.event, number]);

    useEffect(() => {
        const getTeams = async () => {
            console.log("getting teams")
            await fetch(`https://www.thebluealliance.com/api/v3/match/${props.event}_qm${number}`, { headers: { "X-TBA-Auth-Key": "fLKsnM61nLrIA7CDpmFybAXvRYFeCQbIRrPYm5sKSSrs77vsUhL2whnLIHizc3iU" } })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data.score_breakdown)
                    if (data.score_breakdown) {
                        setRedPoints(data.score_breakdown.red.totalPoints - data.score_breakdown.red.foulPoints);
                        setBluePoints(data.score_breakdown.blue.totalPoints - data.score_breakdown.blue.foulPoints);
                    }
                    setRed(data.alliances.red.team_keys);
                    setBlue(data.alliances.blue.team_keys);

                });
        }
        getTeams();
    }, [loading, number]);

    useEffect(() => {
        const asyncFunction = async () => {
            const setTeams = async (teams) => {
                console.log("setting teams")
                let array = [];
                for (var i in teams) {
                    await fetch(`https://server.palyrobotics.com/team/${props.event}/${teams[i].replace(/frc/g, '')}/${number}`)
                        .then((response) => response.json())
                        .then((data) => {
                            array.push(Object.assign({}, data));
                        });
                }
                return array;
            }

            const setStats = async (teams) => {
                console.log("setting stats")
                let array = [];
                for (var i in teams) {
                    await fetch(`https://server.palyrobotics.com/team/${props.event}/${teams[i].replace(/frc/g, '')}`)
                        .then((response) => response.json())
                        .then((data) => {
                            array.push(Object.assign({}, data));
                        })
                }
                return array;
            }

            if (blue) {
                let redArray = await setTeams(red);
                let blueArray = await setTeams(blue);

                let redStats = await setStats(red);
                let blueStats = await setStats(blue);

                setRedStats(redStats);
                setBlueStats(blueStats);

                setLoading(false);
                let matchHappened = false;
                let combinedStats = redStats.concat(blueStats);
                console.log(combinedStats)
                if (combinedStats[0].qm[number]) {
                    Object.keys(combinedStats).map((i) => {
                        console.log(combinedStats[i])
                        if (combinedStats[i].qm[number]["-"] != "-") {
                            matchHappened = true;
                        }
                    })
                }
                console.log(matchHappened);
                setMatchHasHappened(matchHappened);
            }
        }
        asyncFunction();
        if (red && blue && !false) {
            getStatboticsStuffCusFunnyWhyNotNoMatchDataYetSoNeedPredictionBecauseFunnyWhyNotXanderWantDataBigDataFunnyRobotsGoCrazyDataFromRobotsGetAllTimeDataButForSomeReasonThisIsntWorkingRightNowAndICantFetchTheDataButIWillTryToFixItUpdateItStillIsBrokenIMostlyFixedItButThisFunctionIsGettingCalledInAWeirdLoopThatIsBreakingTheWebsite();
        }
    }, [blue, number])

    if (loading) return (
        <div className="container">Loading...</div>
    );

    // if (red && blue && !false) {
    //     getStatboticsStuffCusFunnyWhyNotNoMatchDataYetSoNeedPredictionBecauseFunnyWhyNotXanderWantDataBigDataFunnyRobotsGoCrazyDataFromRobotsGetAllTimeDataButForSomeReasonThisIsntWorkingRightNowAndICantFetchTheDataButIWillTryToFixItUpdateItStillIsBrokenIMostlyFixedItButThisFunctionIsGettingCalledInAWeirdLoopThatIsBreakingTheWebsite();
    // }
    // the two false is cus idk how to check if a match has gone or not yet, please change
    return (
        redStats && blueStats && matchHasHappened ?
            <Grid>
                <Grid.Col span={12} >
                    <Card>
                        <Title order={3}>QM {number}</Title>
                        <Space h="md" />
                        <Grid>
                            <Grid.Col span={6}>
                                <PredictionCard keys={red} alliance={redStats} color={"red"} totalPoints={redPoints} />
                            </Grid.Col>
                            <Grid.Col span={6}>
                                <PredictionCard keys={blue} alliance={blueStats} color={"blue"} totalPoints={bluePoints} />
                            </Grid.Col>
                        </Grid>
                    </Card>
                </Grid.Col>
            </Grid> :
            <Grid>
                <Grid.Col span={12} >
                    <Card>
                        <Title order={3}>QM {number} Robot Averages</Title>
                        <Space h="md" />
                        <Grid>
                            <Grid.Col span={6}>
                                {red1 && red2 && red3 && blue1 && blue2 && blue3 && redStats ? <PrematchCard team1={red1} team2={red2} team3={red3} opps1={blue1} opps2={blue2} opps3={blue3} color={"red"} alliance={redStats} keys={red} /> : <div>Loading...</div>}
                            </Grid.Col>
                            <Grid.Col span={6}>
                                {red1 && red2 && red3 && blue1 && blue2 && blue3 && blueStats ? <PrematchCard team1={blue1} team2={blue2} team3={blue3} opps1={red1} opps2={red2} opps3={red3} color={"blue"} alliance={blueStats} keys={blue} /> : <div>Loading...</div>}
                            </Grid.Col>
                        </Grid>
                    </Card>
                </Grid.Col>
            </Grid>
    );
    // }
}

export default Match;
