import { DataTable } from 'mantine-datatable';
import { useEffect, useState } from 'react';
import { Button } from '@mantine/core';

const PrematchCard = (props) => {
    const [team1, setTeam1] = useState();
    const [team2, setTeam2] = useState();
    const [team3, setTeam3] = useState();

    const [opps1, setOpps1] = useState();
    const [opps2, setOpps2] = useState();
    const [opps3, setOpps3] = useState();
    
    const [allTimeToggle, setToggle] = useState(true);

    const [teams, setTeams] = useState();
    const [keys, setKeys] = useState();

    // function averageData() {
    //     if (props && teams) {
    //         for(var q of teams[0].match.qm) {
    //             console.log(q)
    //         }
    //     }
    // }

    useEffect(() => {
        if (props.alliance && keys) {
            let correctedTeams = []
            
            Object.keys(props.alliance).map((i)=> {
                
                console.log(props.alliance[i])
                correctedTeams.push({"key": keys[i].slice(3), "match": (props.alliance[i].qm || {})})
            })
            setTeams(correctedTeams);
        }
        if (props.keys) {
            setKeys(props.keys);
        }
        // averageData();
    }, [props])

    useEffect(() => {
        if (props) {
            setTeam1(props.team1);
            setTeam2(props.team2);
            setTeam3(props.team3);
            setOpps1(props.opps1);
            setOpps2(props.opps2);
            setOpps3(props.opps3);
        }
      });

      const toggle = () => {
        setToggle(!allTimeToggle);
      };

    if (team1 && team2 && team3 && opps1 && opps2 && opps3) {
        if (allTimeToggle) 
        return ( 
            <DataTable 
                withBorder
                withColumnBorders
                striped
                highlightOnHover
                borderColor={(props.color)}
                records={[
                    {
                        column1: ("Average Points"),
                        column2: (team1.epa.breakdown.total_points),
                        column3: (team2.epa.breakdown.total_points),
                        column4: (team3.epa.breakdown.total_points),
                        
                    },
                    {
                        column1: ("Autos Points"),
                        column2: (team1.epa.breakdown.auto_points),
                        column3: (team2.epa.breakdown.auto_points),
                        column4: (team3.epa.breakdown.auto_points),
                        
                    },
                    {
                        column1: ("Autos Coral"),
                        column2: (team1.epa.breakdown.auto_coral),
                        column3: (team2.epa.breakdown.auto_coral),
                        column4: (team3.epa.breakdown.auto_coral),
                    },
                    {
                        column1: ("Autos Coral Points"),
                        column2: (team1.epa.breakdown.auto_coral_points),
                        column3: (team2.epa.breakdown.auto_coral_points),
                        column4: (team3.epa.breakdown.auto_coral_points),
                    },
                    {
                        column1: ("Teleop Points"),
                        column2: (team1.epa.breakdown.teleop_points),
                        column3: (team2.epa.breakdown.teleop_points),
                        column4: (team3.epa.breakdown.teleop_points),
                        
                    },
                    {
                        column1: ("Teleop Coral"),
                        column2: (team1.epa.breakdown.teleop_coral),
                        column3: (team2.epa.breakdown.teleop_coral),
                        column4: (team3.epa.breakdown.teleop_coral),
                    },
                    {
                        column1: ("Teleop Coral Points"),
                        column2: (team1.epa.breakdown.teleop_coral_points),
                        column3: (team2.epa.breakdown.teleop_coral_points),
                        column4: (team3.epa.breakdown.teleop_coral_points),
                    },
                    {
                        column1: ("Level Coral Breakdown"),
                        column2: (`L1: ${team1.epa.breakdown.coral_l1} | L2: ${team1.epa.breakdown.coral_l2} | L3: ${team1.epa.breakdown.coral_l3} | L4: ${team1.epa.breakdown.coral_l4}`),
                        column3: (`L1: ${team2.epa.breakdown.coral_l1} | L2: ${team2.epa.breakdown.coral_l2} | L3: ${team2.epa.breakdown.coral_l3} | L4: ${team2.epa.breakdown.coral_l4}`),
                        column4: (`L1: ${team3.epa.breakdown.coral_l1} | L2: ${team3.epa.breakdown.coral_l2} | L3: ${team3.epa.breakdown.coral_l3} | L4: ${team3.epa.breakdown.coral_l4}`),
                        
                    },
                    {
                        column1: ("Processor Algae"),
                        column2: (team1.epa.breakdown.processor_algae),
                        column3: (team2.epa.breakdown.processor_algae),
                        column4: (team3.epa.breakdown.processor_algae),
                    },
                    {
                        column1: ("Barge Points"),
                        column2: (team1.epa.breakdown.barge_points),
                        column3: (team2.epa.breakdown.barge_points),
                        column4: (team3.epa.breakdown.barge_points),
                    },
                    {
                        column1: ("Endgame Points"),
                        column2: (team1.epa.breakdown.endgame_points),
                        column3: (team2.epa.breakdown.endgame_points),
                        column4: (team3.epa.breakdown.endgame_points),
                    },
                    {
                        column1: ("Estimated Points"),
                        column2: ((team1.epa.breakdown.total_points + team2.epa.breakdown.total_points + team3.epa.breakdown.total_points).toFixed(0)),
                        column3: ("Win Percentage"),
                        column4: (((1 / ( 1 + Math.pow(10,( ((opps1.epa.breakdown.total_points + opps2.epa.breakdown.total_points + opps3.epa.breakdown.total_points) - (team1.epa.breakdown.total_points + team2.epa.breakdown.total_points + team3.epa.breakdown.total_points)) / 30 )) ) ) * 100).toFixed(2) + '%'),
                    }
                ]}
                columns={[
                    { accessor: 'column1', title: "Data", width: 20, textAlignment: 'center', ellipsis: true},
                    { accessor: 'column2', title: String(team1.team), width: 15, textAlignment: 'center'},
                    { accessor: 'column3', title: String(team2.team), width: 15, textAlignment: 'center'},
                    { accessor: 'column4', title: String(team3.team), width: 15, textAlignment: 'center'}, 
                    // Replace with keys String(teams[0].team) -> keys
                ]}
            />   
        ); 
        else
        return ( 
            <DataTable 
                withBorder
                withColumnBorders
                striped
                highlightOnHover
                borderColor={(props.color)}
                records={[
                    {
                        column1: ("Average Points"),
                        column2: (teams[0].qm1),
                        column3: (team2.epa.breakdown.total_points),
                        column4: (team3.epa.breakdown.total_points),
                        
                    },
                    {
                        column1: ("Autos Points"),
                        column2: (team1.epa.breakdown.auto_points),
                        column3: (team2.epa.breakdown.auto_points),
                        column4: (team3.epa.breakdown.auto_points),
                        
                    },
                    {
                        column1: ("Autos Coral"),
                        column2: (team1.epa.breakdown.auto_coral),
                        column3: (team2.epa.breakdown.auto_coral),
                        column4: (team3.epa.breakdown.auto_coral),
                    },
                    {
                        column1: ("Autos Coral Points"),
                        column2: (team1.epa.breakdown.auto_coral_points),
                        column3: (team2.epa.breakdown.auto_coral_points),
                        column4: (team3.epa.breakdown.auto_coral_points),
                    },
                    {
                        column1: ("Teleop Points"),
                        column2: (team1.epa.breakdown.teleop_points),
                        column3: (team2.epa.breakdown.teleop_points),
                        column4: (team3.epa.breakdown.teleop_points),
                        
                    },
                    {
                        column1: ("Teleop Coral"),
                        column2: (team1.epa.breakdown.teleop_coral),
                        column3: (team2.epa.breakdown.teleop_coral),
                        column4: (team3.epa.breakdown.teleop_coral),
                    },
                    {
                        column1: ("Teleop Coral Points"),
                        column2: (team1.epa.breakdown.teleop_coral_points),
                        column3: (team2.epa.breakdown.teleop_coral_points),
                        column4: (team3.epa.breakdown.teleop_coral_points),
                    },
                    {
                        column1: ("Level Coral Breakdown"),
                        column2: (`L1: ${team1.epa.breakdown.coral_l1} | L2: ${team1.epa.breakdown.coral_l2} | L3: ${team1.epa.breakdown.coral_l3} | L4: ${team1.epa.breakdown.coral_l4}`),
                        column3: (`L1: ${team2.epa.breakdown.coral_l1} | L2: ${team2.epa.breakdown.coral_l2} | L3: ${team2.epa.breakdown.coral_l3} | L4: ${team2.epa.breakdown.coral_l4}`),
                        column4: (`L1: ${team3.epa.breakdown.coral_l1} | L2: ${team3.epa.breakdown.coral_l2} | L3: ${team3.epa.breakdown.coral_l3} | L4: ${team3.epa.breakdown.coral_l4}`),
                        
                    },
                    {
                        column1: ("Processor Algae"),
                        column2: (team1.epa.breakdown.processor_algae),
                        column3: (team2.epa.breakdown.processor_algae),
                        column4: (team3.epa.breakdown.processor_algae),
                    },
                    {
                        column1: ("Barge Points"),
                        column2: (team1.epa.breakdown.barge_points),
                        column3: (team2.epa.breakdown.barge_points),
                        column4: (team3.epa.breakdown.barge_points),
                    },
                    {
                        column1: ("Endgame Points"),
                        column2: (team1.epa.breakdown.endgame_points),
                        column3: (team2.epa.breakdown.endgame_points),
                        column4: (team3.epa.breakdown.endgame_points),
                    },
                    {
                        column1: ("Estimated Points"),
                        column2: ((team1.epa.breakdown.total_points + team2.epa.breakdown.total_points + team3.epa.breakdown.total_points).toFixed(0)),
                        column3: ("Win Percentage"),
                        column4: (((1 / ( 1 + Math.pow(10,( ((opps1.epa.breakdown.total_points + opps2.epa.breakdown.total_points + opps3.epa.breakdown.total_points) - (team1.epa.breakdown.total_points + team2.epa.breakdown.total_points + team3.epa.breakdown.total_points)) / 30 )) ) ) * 100).toFixed(2) + '%'),
                    }
                ]}
                columns={[
                    { accessor: 'column1', title: "Data", width: 20, textAlignment: 'center', ellipsis: true},
                    { accessor: 'column2', title: String(team1.team), width: 15, textAlignment: 'center'},
                    { accessor: 'column3', title: String(team2.team), width: 15, textAlignment: 'center'},
                    { accessor: 'column4', title: String(team3.team), width: 15, textAlignment: 'center'}, 
                    // Replace with keys String(teams[0].team) -> keys
                ]}
            />   
        ); 
    } else {
        return (
            <div>Loading...</div>
        )
    }
}

export default PrematchCard;