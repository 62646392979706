import { Card, Divider, Text, Title, Group, Center, Table, Grid, Flex, Space } from '@mantine/core';
import { useEffect, useState } from 'react';

const ChargeStationCard = (props) => {
    const [robotDied, setRobotDied] = useState(0);
    // useEffect(() => {
    //     console.log(props.stats)
    // })

    useEffect(() => {
        if (props.stats) {
            setRobotDied(props.stats.total.died)
        }
    }, [props]);

    return (
        (props.stats &&
            <Card>
                <Title order={3}>Endgame</Title>

                <Grid grow  >
                    <Grid.Col span={4} >

                        <Table>

                            <thead>
                                <tr>
                                    <th>N/A</th>
                                    <th>Parked</th>
                                    <th>Shallow</th>
                                    <th>Deep</th>
                                    <th>Died</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>

                                    {props.stats ? (
                                        <>
                                            <td>{Math.round((1 - (props.stats.percent.parkPercent + props.stats.percent.deepPercent + props.stats.percent.shallowPercent)) * 10000)/100}</td>
                                            <td>{Math.round(props.stats.percent.parkPercent * 10000)/100}</td>
                                            <td>{Math.round(props.stats.percent.shallowPercent * 10000)/100}</td>
                                            <td>{Math.round(props.stats.percent.deepPercent * 10000)/100}</td>
                                            <td>{props.stats.total.died}</td>
                                        </>
                                    ) : (
                                        <>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                        </>

                                    )
                                    }

                                </tr>
                            </tbody>

                        </Table>
                    </Grid.Col>
                    <Divider orientation="vertical" size='sm' />
                </Grid>



            </Card>
        )
    );
}

export default ChargeStationCard;