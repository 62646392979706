var _ = require('lodash');

const contains = (list, target) => {
    for (let i = 0; i < list.length; i++){
        if (_.isEqual(list[i], target)){
            return true;
        }
    }
    return false;
}

const findDifferences = (og, changed, currDiff, override) => {
    console.log("Comparing:", JSON.stringify(og), "vs", JSON.stringify(changed));

    if (override) {
        // console.log("Override is enabled. Returning changed object.");
        return changed;
    }

    var diffList = [];

    if (_.isEqual(og, changed)) {
        console.log("No change detected");
        return "no change";
    }

    if (typeof og == "object" && typeof changed == "object") {
        const ogKeys = Object.keys(og);
        const changedKeys = Object.keys(changed);

        console.log("Keys in original:", ogKeys);
        console.log("Keys in changed:", changedKeys);

        if (_.isEqual(ogKeys, changedKeys)) {
            console.log("Keys match, checking values...");
            if (Array.isArray(og)) {
                var startChange = null;
                var endChange = null;
                for (var i = 0; i < og.length; i++) {
                    if (!_.isEqual(og[i], changed[i])) {
                        if (typeof startChange != "number") {
                            startChange = i;
                        }
                        endChange = i;
                    }
                }
                if (startChange !== null && endChange !== null) {
                    console.log(`Detected changes in array from index ${startChange} to ${endChange}`);
                }

                if (_.isEqual(og[startChange], changed[endChange])) {
                    console.log(`Reorder detected: moving index ${startChange} to ${endChange}`);
                    diffList.push({ "coordinate": [...currDiff, startChange], "mode": "reorder", "value": endChange });

                    for (var i = startChange + 1; i > startChange && i <= endChange; i++) {
                        const diffs = findDifferences(og[i], changed[i - 1], [...currDiff, i - 1]);
                        if (diffs != "no change") {
                            diffList = [...diffList, ...diffs];
                        }
                    }
                } else if (_.isEqual(og[endChange], changed[startChange])) {
                    console.log(`Reorder detected: moving index ${endChange} to ${startChange}`);
                    diffList.push({ "coordinate": [...currDiff, endChange], "mode": "reorder", "value": startChange });

                    for (var i = startChange; i >= startChange && i < endChange; i++) {
                        const diffs = findDifferences(og[i], changed[i + 1], [...currDiff, i + 1]);
                        if (diffs != "no change") {
                            diffList = [...diffList, ...diffs];
                        }
                    }
                } else {
                    console.log("Checking differences in array values...");
                    ogKeys.forEach((key) => {
                        const diffs = findDifferences(og[key], changed[key], [...currDiff, key]);
                        if (diffs != "no change") {
                            diffList = [...diffList, ...diffs];
                        }
                    });
                }
            } else {
                console.log("Checking differences in object values...");
                ogKeys.forEach((key) => {
                    const diffs = findDifferences(og[key], changed[key], [...currDiff, key]);
                    if (diffs != "no change") {
                        diffList = [...diffList, ...diffs];
                    }
                });
            }
        } else {
            console.log("Keys differ, detecting insertions and deletions...");
            const isArray = Array.isArray(og);

            let normal = [];
            let modifiedOgKeys = [...ogKeys];
            let modifiedOgVals = Object.values(og); // FIXED
            let modifiedChangedKeys = [...changedKeys];
            let modifiedChangedVals = Object.values(changed); // FIXED

            let modifiedOgList = (isArray ? modifiedOgVals : modifiedOgKeys);
            let modifiedChangedList = (isArray ? modifiedChangedVals : modifiedChangedKeys);

            // modifiedOgList.forEach((key) => {
            for (let i = 0; i < modifiedOgList.length; i++) {
                const key = modifiedOgList[i];
                console.log(key)
                console.log(contains(modifiedChangedList, key))
                if (contains(modifiedChangedList, key)) {
                    normal.push(key);
                    console.log("og removal index")
                    console.log(_.findIndex(modifiedOgList, key))
                    console.log("changed index: ")
                    console.log(_.findIndex(modifiedChangedList, key))
                    if (typeof (modifiedOgList[0]) == "string") {
                        modifiedOgList.splice(modifiedOgList.indexOf(key), 1);
                        modifiedChangedList.splice(modifiedChangedList.indexOf(key), 1);
                    } else {
                        modifiedOgList.splice(_.findIndex(modifiedOgList, key), 1);
                        modifiedChangedList.splice(_.findIndex(modifiedChangedList, key), 1);
                    }
                    i--;
                }
                console.log(normal)
                console.log(modifiedOgList)
                console.log(modifiedChangedList)
            };

            (!isArray && normal.forEach((key) => {
                const diffs = findDifferences(og[key], changed[key], [...currDiff, key]);
                if (diffs != "no change") {
                    diffList = [...diffList, ...diffs];
                }
            }));

            modifiedOgList.forEach((key) => {
                console.log("Detected deletion:", key);
                if (isArray) {
                    diffList.push({ "coordinate": [...currDiff, _.findIndex(og, key)], "mode": "deletion", value: key });
                } else {
                    diffList.push({ "coordinate": [...currDiff, key], "mode": "deletion", value: og[key] });
                }
            });

            modifiedChangedList.forEach((key) => {
                console.log("Detected insertion:", key);
                if (isArray) {
                    diffList.push({ "coordinate": [...currDiff, _.findIndex(changed, key)], "mode": "insertion", "value": key });
                } else {
                    diffList.push({ "coordinate": [...currDiff, key], "mode": "insertion", "value": changed[key] });
                }
            });
        }
    } else {
        console.log(`Detected edit at ${currDiff.join(" -> ")}: changing ${JSON.stringify(og)} to ${JSON.stringify(changed)}`);
        console.log(override)
        if (override) {
            console.log("Override is enabled, forcing the change.");
            return [{ "coordinate": currDiff, "mode": "edit", "value": changed }];
        }
        diffList.push({ "coordinate": currDiff, "mode": "edit", "value": changed });
    }

    for (let i = 0; i < diffList.length; i++) {
        for (let j = i; j < diffList.length; j++) {
            if (_.isEqual(diffList[i].value, diffList[j].value)) {
                if (diffList[i].mode == "deletion" && diffList[j].mode == "insertion") {
                    diffList.push({ "coordinate": diffList[i].coordinate, "mode": "key change", value: diffList[j].coordinate });
                    diffList.splice(i, 1);
                    diffList.splice(j - 1, 1);
                    i--;
                    j--;
                } else if (diffList[j].mode == "deletion" && diffList[i].mode == "insertion") {
                    diffList.push({ "coordinate": diffList[j].coordinate, "mode": "key change", value: diffList[i].coordinate });
                    diffList.splice(i, 1);
                    diffList.splice(j - 1, 1);
                    i--;
                    j--;
                }

            }

        }
    }

    if (og.field){
        let rowReset = false;
        for (let i = 0; i < diffList.length; i++){
            if (diffList[i].mode == "insertion" || diffList[i].mode == "deletion" || (diffList[i].mode == "edit" && diffList[i].coordinate.includes("rows"))){
                diffList.splice(i, 1)
                i--
                rowReset = true
            }
        }
        if (rowReset){
            diffList.push({"coordinate": [...currDiff, "rows"], mode: "edit", value: changed.rows});
        }
        console.log("row reset: ")
        console.log(diffList);
    }

    return diffList;
}

module.exports = { findDifferences };
