import { useState, useEffect } from 'react';
import { Card, Title, Table, Tooltip, Text, Center, Space, Box, Flex, TextBox, Group, Button } from '@mantine/core';

const DataCard = (props) => {
    const [autoL1, setaL1] = useState(0);
    const [autoL2, setaL2] = useState(0);
    const [autoL3, setaL3] = useState(0);
    const [autoL4, setaL4] = useState(0);
    const [teleL1, settL1] = useState(0);
    const [teleL2, settL2] = useState(0);
    const [teleL3, settL3] = useState(0);
    const [teleL4, settL4] = useState(0);
    const [autoalgaep, setautoalgaep] = useState(0);
    const [autoalgaer, setautoalgaer] = useState(0);
    const [autoalgaeh, setautoalgaeh] = useState(0);
    const [autoalgael, setautoalgael] = useState(0);
    const [telealgaep, settelealgaep] = useState(0);
    const [telealgaen, settelealgaen] = useState(0);
    const [telealgaer, settelealgaer] = useState(0);
    const [telealgaeh, settelealgaeh] = useState(0);
    const [telealgaehi, settelealgaehi] = useState(0);
    const [telealgael, settelealgael] = useState(0);
    const [teleintakeg, setteleintakeg] = useState(0);
    const [teleintakes, setteleintakes] = useState(0);
    const [selectedOption, setSelectedOption] = useState('avg');
    const [teamData, setTeamData] = useState();
    const [loading, setLoading] = useState(true);

    const funny = async () => {
        await fetch(`https://api.statbotics.io/v3/team_year/${props.team}/2025`)
            .then((response) => response.json())
            .then((data) => {
                setTeamData(data);
                setLoading(false);
            });
    }

    funny();

    const handleChange = (event) => {
        if (props.stats) {
            setSelectedOption(event);
            if (props.stats && event === "avg") {
                setaL1(Math.round(props.stats.avg.autoCoral1 * 100) / 100);
                setaL2(Math.round(props.stats.avg.autoCoral2 * 100) / 100);
                setaL3(Math.round(props.stats.avg.autoCoral3 * 100) / 100);
                setaL4(Math.round(props.stats.avg.autoCoral4 * 100) / 100);
                settL1(Math.round(props.stats.avg.teleopCoral1 * 100) / 100);
                settL2(Math.round(props.stats.avg.teleopCoral2 * 100) / 100);
                settL3(Math.round(props.stats.avg.teleopCoral3 * 100) / 100);
                settL4(Math.round(props.stats.avg.teleopCoral4 * 100) / 100);
                setautoalgaep(Math.round(props.stats.avg.autoAlgaeProcessor * 100) / 100);
                setautoalgaer(Math.round(props.stats.avg.autoAlgaeRobotNet * 100) / 100);
                setautoalgaeh(Math.round(props.stats.avg.autoAlgaeRemovedHigh * 100) / 100);
                setautoalgael(Math.round(props.stats.avg.autoAlgaeRemovedLow * 100) / 100);
                settelealgaep(Math.round(props.stats.avg.teleopAlgaeProcessor * 100) / 100);
                settelealgaer(Math.round(props.stats.avg.teleopAlgaeRobotNet * 100) / 100);
                settelealgaeh(Math.round(props.stats.avg.teleopAlgaeHumanNet * 100) / 100);
                settelealgaehi(Math.round(props.stats.avg.teleopAlgaeRemovedHigh * 100) / 100);
                settelealgael(Math.round(props.stats.avg.teleopAlgaeRemovedLow * 100) / 100);
                setteleintakeg(Math.round(props.stats.avg.groundIntakes * 100) / 100);
                setteleintakes(Math.round(props.stats.avg.substationIntakes * 100) / 100);
            }
            else if (props.stats && event === "total") {
                setaL1(Math.round(props.stats.total.autoCoral1 * 100) / 100);
                setaL2(Math.round(props.stats.total.autoCoral2 * 100) / 100);
                setaL3(Math.round(props.stats.total.autoCoral3 * 100) / 100);
                setaL4(Math.round(props.stats.total.autoCoral4 * 100) / 100);
                settL1(Math.round(props.stats.total.teleopCoral1 * 100) / 100);
                settL2(Math.round(props.stats.total.teleopCoral2 * 100) / 100);
                settL3(Math.round(props.stats.total.teleopCoral3 * 100) / 100);
                settL4(Math.round(props.stats.total.teleopCoral4 * 100) / 100);
                setautoalgaep(Math.round(props.stats.total.autoAlgaeProcessor * 100) / 100);
                setautoalgaer(Math.round(props.stats.total.autoAlgaeRobotNet * 100) / 100);
                setautoalgaeh(Math.round(props.stats.total.autoAlgaeRemovedHigh * 100) / 100);
                setautoalgael(Math.round(props.stats.total.autoAlgaeRemovedLow * 100) / 100);
                settelealgaep(Math.round(props.stats.total.teleopAlgaeProcessor * 100) / 100);
                settelealgaer(Math.round(props.stats.total.teleopAlgaeRobotNet * 100) / 100);
                settelealgaeh(Math.round(props.stats.total.teleopAlgaeHumanNet * 100) / 100);
                settelealgaehi(Math.round(props.stats.total.teleopAlgaeRemovedHigh * 100) / 100);
                settelealgael(Math.round(props.stats.total.teleopAlgaeRemovedLow * 100) / 100);
                setteleintakeg(Math.round(props.stats.total.groundIntakes * 100) / 100);
                setteleintakes(Math.round(props.stats.total.substationIntakes * 100) / 100);
            }
            else if (props.stats && event === "min") {
                setaL1(Math.round(props.stats.min.autoCoral1 * 100) / 100);
                setaL2(Math.round(props.stats.min.autoCoral2 * 100) / 100);
                setaL3(Math.round(props.stats.min.autoCoral3 * 100) / 100);
                setaL4(Math.round(props.stats.min.autoCoral4 * 100) / 100);
                settL1(Math.round(props.stats.min.teleopCoral1 * 100) / 100);
                settL2(Math.round(props.stats.min.teleopCoral2 * 100) / 100);
                settL3(Math.round(props.stats.min.teleopCoral3 * 100) / 100);
                settL4(Math.round(props.stats.min.teleopCoral4 * 100) / 100);
                setautoalgaep(Math.round(props.stats.min.autoAlgaeProcessor * 100) / 100);
                setautoalgaer(Math.round(props.stats.min.autoAlgaeRobotNet * 100) / 100);
                setautoalgaeh(Math.round(props.stats.min.autoAlgaeRemovedHigh * 100) / 100);
                setautoalgael(Math.round(props.stats.min.autoAlgaeRemovedLow * 100) / 100);
                settelealgaep(Math.round(props.stats.min.teleopAlgaeProcessor * 100) / 100);
                settelealgaer(Math.round(props.stats.min.teleopAlgaeRobotNet * 100) / 100);
                settelealgaeh(Math.round(props.stats.min.teleopAlgaeHumanNet * 100) / 100);
                settelealgaehi(Math.round(props.stats.min.teleopAlgaeRemovedHigh * 100) / 100);
                settelealgael(Math.round(props.stats.min.teleopAlgaeRemovedLow * 100) / 100);
                setteleintakeg(Math.round(props.stats.min.groundIntakes * 100) / 100);
                setteleintakes(Math.round(props.stats.min.substationIntakes * 100) / 100);
            }
            else if (props.stats && event === "max") {
                setaL1(Math.round(props.stats.max.autoCoral1 * 100) / 100);
                setaL2(Math.round(props.stats.max.autoCoral2 * 100) / 100);
                setaL3(Math.round(props.stats.max.autoCoral3 * 100) / 100);
                setaL4(Math.round(props.stats.max.autoCoral4 * 100) / 100);
                settL1(Math.round(props.stats.max.teleopCoral1 * 100) / 100);
                settL2(Math.round(props.stats.max.teleopCoral2 * 100) / 100);
                settL3(Math.round(props.stats.max.teleopCoral3 * 100) / 100);
                settL4(Math.round(props.stats.max.teleopCoral4 * 100) / 100);
                setautoalgaep(Math.round(props.stats.max.autoAlgaeProcessor * 100) / 100);
                setautoalgaer(Math.round(props.stats.max.autoAlgaeRobotNet * 100) / 100);
                setautoalgaeh(Math.round(props.stats.max.autoAlgaeRemovedHigh * 100) / 100);
                setautoalgael(Math.round(props.stats.max.autoAlgaeRemovedLow * 100) / 100);
                settelealgaep(Math.round(props.stats.max.teleopAlgaeProcessor * 100) / 100);
                settelealgaer(Math.round(props.stats.max.teleopAlgaeRobotNet * 100) / 100);
                settelealgaeh(Math.round(props.stats.max.teleopAlgaeHumanNet * 100) / 100);
                settelealgaehi(Math.round(props.stats.max.teleopAlgaeRemovedHigh * 100) / 100);
                settelealgael(Math.round(props.stats.max.teleopAlgaeRemovedLow * 100) / 100);
                setteleintakeg(Math.round(props.stats.max.groundIntakes * 100) / 100);
                setteleintakes(Math.round(props.stats.max.substationIntakes * 100) / 100);
            }
            else if (props.stats && event === "percent") {
                setaL1("N/A");
                setaL2("N/A");
                setaL3("N/A");
                setaL4("N/A");
                settL1(`${Math.round(props.stats.percent.coral1Prob * 10000) / 100}%`);
                settL2(`${Math.round(props.stats.percent.coral2Prob * 10000) / 100}%`);
                settL3(`${Math.round(props.stats.percent.coral3Prob * 10000) / 100}%`);
                settL4(`${Math.round(props.stats.percent.coral4Prob * 10000) / 100}%`);
                setautoalgaep("N/A");
                setautoalgaer("N/A");
                setautoalgaeh("N/A");
                setautoalgael("N/A");
                settelealgaep(`${Math.round(props.stats.percent.algaeProcessor * 10000) / 100}%`);
                settelealgaen(`${Math.round((1-props.stats.percent.algaeProcessor) * 10000) / 100}%`)
                settelealgaer(`${Math.round(props.stats.percent.robotNetAcc * 10000) / 100}%`);
                settelealgaeh(`${Math.round(props.stats.percent.humanNetAcc * 10000) / 100}%`);
                settelealgaehi(`${Math.round(props.stats.percent.algaeHighProb * 10000) / 100}%`);
                settelealgael(`${Math.round(props.stats.percent.algaeLowProb * 10000) / 100}%`);
                setteleintakeg(Math.round(props.stats.percent.coralIntakeGroundProb * 100) / 100);
                setteleintakes(Math.round(props.stats.percent.coralIntakeSubstationProb * 100) / 100);
            } else {
                setaL1("N/A");
                setaL2("N/A");
                setaL3("N/A");
                setaL4("N/A");
                settL1(`${teamData.epa.breakdown.coral_l1}`);
                settL2(`${teamData.epa.breakdown.coral_l2}`);
                settL3(`${teamData.epa.breakdown.coral_l3}`);
                settL4(`${teamData.epa.breakdown.coral_l4}`);
                setautoalgaep("N/A");
                setautoalgaer("N/A");
                setautoalgaeh("N/A");
                setautoalgael("N/A");
                settelealgaep(`${Math.round(props.stats.percent.algaeProcessor * 10000) / 100}%`);
                settelealgaen(`${Math.round((1-props.stats.percent.algaeProcessor) * 10000) / 100}%`)
                settelealgaer(`${Math.round(props.stats.percent.robotNetAcc * 10000) / 100}%`);
                settelealgaeh(`${Math.round(props.stats.percent.humanNetAcc * 10000) / 100}%`);
                settelealgaehi(`${Math.round(props.stats.percent.algaeHighProb * 10000) / 100}%`);
                settelealgael(`${Math.round(props.stats.percent.algaeLowProb * 10000) / 100}%`);
                setteleintakeg(Math.round(props.stats.percent.coralIntakeGroundProb * 100) / 100);
                setteleintakes(Math.round(props.stats.percent.coralIntakeSubstationProb * 100) / 100);
            }
        }
    };
    useEffect(() => {
        if (props.stats) {
            console.log(props.stats);
            setaL1(Math.round(props.stats.avg.autoCoral1 * 100) / 100);
            setaL2(Math.round(props.stats.avg.autoCoral2 * 100) / 100);
            setaL3(Math.round(props.stats.avg.autoCoral3 * 100) / 100);
            setaL4(Math.round(props.stats.avg.autoCoral4 * 100) / 100);
            settL1(Math.round(props.stats.avg.teleopCoral1 * 100) / 100);
            settL2(Math.round(props.stats.avg.teleopCoral2 * 100) / 100);
            settL3(Math.round(props.stats.avg.teleopCoral3 * 100) / 100);
            settL4(Math.round(props.stats.avg.teleopCoral4 * 100) / 100);
            setautoalgaep(Math.round(props.stats.avg.autoAlgaeProcessor * 100) / 100);
            setautoalgaer(Math.round(props.stats.avg.autoAlgaeRobotNet * 100) / 100);
            setautoalgaeh(Math.round(props.stats.avg.autoAlgaeRemovedHigh * 100) / 100);
            setautoalgael(Math.round(props.stats.avg.autoAlgaeRemovedLow * 100) / 100);
            settelealgaep(Math.round(props.stats.avg.teleopAlgaeProcessor * 100) / 100);
            settelealgaer(Math.round(props.stats.avg.teleopAlgaeRobotNet * 100) / 100);
            settelealgaeh(Math.round(props.stats.avg.teleopAlgaeHumanNet * 100) / 100);
            settelealgaehi(Math.round(props.stats.avg.teleopAlgaeRemovedHigh * 100) / 100);
            settelealgael(Math.round(props.stats.avg.teleopAlgaeRemovedLow * 100) / 100);
            setteleintakeg(Math.round(props.stats.avg.groundIntakes * 100) / 100);
            setteleintakes(Math.round(props.stats.avg.substationIntakes * 100) / 100);
        }
    }, [props]);

    if (loading) return (
        <div className="container">Loading...</div>
    );

    return (
        <Card>
            <Group justify="right">
                <Button
                    type="radio"
                    variant={selectedOption === 'avg' ? "filled" : "default"}
                    onClick={() => {
                        handleChange("avg")
                    }}
                    style={{ padding: "12px" }}>
                    Average
                </Button>
                <Button
                    type="radio"
                    variant={selectedOption === 'percent' ? "filled" : "default"}
                    onClick={() => {
                        handleChange("percent")
                    }}
                    style={{ padding: "12px" }}>
                    Percent
                </Button>
                <Button
                    type="radio"
                    variant={selectedOption === 'min' ? "filled" : "default"}
                    onClick={() => {
                        handleChange("min")
                    }}
                    style={{ padding: "12px" }}>
                    Minimum
                </Button>
                <Button
                    type="radio"
                    variant={selectedOption === 'max' ? "filled" : "default"}
                    onClick={() => {
                        handleChange("max")
                    }}
                    style={{ padding: "12px" }}>
                    Maximum
                </Button>
                <Button
                    type="radio"
                    variant={selectedOption === 'total' ? "filled" : "default"}
                    onClick={() => {
                        handleChange("total")
                    }}
                    style={{ padding: "12px" }}>
                    Total
                </Button>
                <Button
                    type="radio"
                    variant={selectedOption === 'epa' ? "filled" : "default"}
                    onClick={() => {
                        handleChange("epa")
                    }}
                    style={{ padding: "12px" }}>
                    EPA
                </Button>
            </Group>

            <Space h="md" />
            {selectedOption !== "percent" && selectedOption !== "epa" &&
                <div>
                    <Title order={3}>Auto</Title>
                    <Space h="md" />

                    <Table highlightOnHover>
                        <thead>
                            <tr>
                                <th><Title order={4}>Coral</Title></th>
                            </tr>
                        </thead>
                        <tbody>{
                            <Flex justify="space-between" align="center" width="100%" padding="10px">
                                <Text></Text>
                                <Text>L1 : {autoL1}</Text>
                                <Text>L2 : {autoL2}</Text>
                                <Text>L3 : {autoL3}</Text>
                                <Text>L4 : {autoL4}</Text>
                                <Text></Text>
                            </Flex>
                        }</tbody>
                    </Table>

                    <Space h="md" />
                    <Table highlightOnHover>
                        <thead>
                            <tr>
                                <th><Title order={4}>Algae Scored</Title></th>
                            </tr>
                        </thead>
                        <tbody>{
                            <Flex justify="space-between" align="center" width="100%" padding="10px">
                                <Text></Text>
                                <Text>Robot Net : {autoalgaer}</Text>
                                <Text>Processor : {autoalgaep}</Text>
                                <Text></Text>
                            </Flex>
                        }</tbody>
                    </Table>

                    <Space h="md" />
                    <Table highlightOnHover>
                        <thead>
                            <tr>
                                <th><Title order={4}>Algae Taken</Title></th>
                            </tr>
                        </thead>
                        <tbody>{
                            <Flex justify="space-between" align="center" width="100%" padding="10px">
                                <Text></Text>
                                <Text>High : {autoalgaeh}</Text>
                                <Text>Low : {autoalgael}</Text>
                                <Text></Text>
                            </Flex>
                        }</tbody>
                    </Table>

                    <Space h="md" />
                    {/* <AutoModal matches={props.matches} /> */}

                    <Title order={3}>Teleop</Title>
                </div>
            }
            <Space h="md" />

            <Table highlightOnHover>
                <thead>
                    <tr>
                        <th><Title order={4}>Coral</Title></th>
                    </tr>
                </thead>
                <tbody>{
                    <Flex justify="space-between" align="center" width="100%" padding="10px">
                        <Text></Text>
                        <Text>L1 : {teleL1}</Text>
                        <Text>L2 : {teleL2}</Text>
                        <Text>L3 : {teleL3}</Text>
                        <Text>L4 : {teleL4}</Text>
                        <Text></Text>
                    </Flex>
                }</tbody>
            </Table>

            <Space h="md" />
            <Table highlightOnHover>
                <thead>
                    <tr>
                        <th><Title order={4}>Algae</Title></th>
                    </tr>
                </thead>
                <tbody>

                    {selectedOption !== "percent" && selectedOption !== "epa" ?
                        <Flex justify="space-between" align="center" width="100%" padding="10px">
                            <Text></Text>
                            <Text>Robot Net : {telealgaer}</Text>
                            <Text>Processor : {telealgaep}</Text>
                            <Text>Human : {telealgaeh}</Text>
                            <Text></Text>
                        </Flex>
                        :
                        <Flex direction="column">
                            <h4 style={{marginLeft: "5%"}}>Location %</h4>
                            <Flex justify="space-between" align="center" width="100%" padding="10px">
                                <Text></Text>
                                <Text>Robot Net : {telealgaen}</Text>
                                <Text>Processor : {telealgaep}</Text>
                                <Text></Text>
                            </Flex>
                            <h4 style={{marginLeft: "5%"}}>Accuracy</h4>
                            <Flex justify="space-between" align="center" width="100%" padding="10px">
                                <Text></Text>
                                <Text>Robot Net : {telealgaer}</Text>
                                <Text>Human Net: {telealgaeh}</Text>
                                <Text></Text>
                            </Flex>

                        </Flex>

                    }</tbody>
            </Table>

            <Space h="md" />
            <Table highlightOnHover>
                <thead>
                    <tr>
                        <th><Title order={4}>Algae Taken</Title></th>
                    </tr>
                </thead>
                <tbody>{
                    <Flex justify="space-between" align="center" width="100%" padding="10px">
                        <Text></Text>
                        <Text>High : {telealgaehi}</Text>
                        <Text>Low : {telealgael}</Text>
                        <Text></Text>
                    </Flex>
                }</tbody>
            </Table>

            <Table highlightOnHover>
                <thead>
                    <tr>
                        <th><Title order={4}>intook :p</Title></th>
                    </tr>
                </thead>
                <tbody>{
                    <Flex justify="space-between" align="center" width="100%" padding="10px">
                        <Text></Text>
                        <Text>grund : {teleintakeg}</Text>
                        <Text>subway Sandwhich : {teleintakes}</Text>
                        <Text></Text>
                    </Flex>
                }</tbody>
            </Table>

            <Space h="md" />

        </Card>
    )
}

export default DataCard;